<template>
  <v-dialog
    v-model="dialogSalesDuplicadas"
    @click:outside="$emit('update:dialogSalesDuplicadas', false)"
    @keydown.esc="$emit('update:dialogSalesDuplicadas', false)"
    max-width="1200px"
  >
    <v-card class="pa-6">
      <v-card-title>
        <v-icon color="xbColor" left>mdi-account-plus-outline</v-icon>
        <span class="primary--text"> Cadastrar Usuário Venda </span>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogSalesDuplicadas', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-10 text-center">
        <span class="primary--text text-subtitle-1">
          Os Usuários não podem ter o mesmo email e numero de documento
          cadastrado.
        </span>
        <v-form @submit.prevent ref="form" v-model="validForm" lazy-validation>
          <v-row class="mt-10" dense>
            <!--Sale Id-->
            <v-col cols="12" md="4">
              <v-text-field
                disabled
                v-model="item.sale_id"
                label="Numero da Venda"
                dense
              ></v-text-field>
            </v-col>
            <!-- Nome -->
            <v-col cols="12" md="4">
              <v-text-field
                v-model="item.client_name"
                label="Nome"
                dense
                required
                :rules="formRules"
              ></v-text-field>
            </v-col>

            <!-- Email -->
            <v-col cols="12" md="4">
              <v-text-field
                v-model="item.client_email"
                label="Email"
                dense
                required
                :rules="formRules"
              ></v-text-field>
            </v-col>

            <!-- Celular -->
            <v-col class="" cols="12" md="4">
              <v-text-field
                v-model="item.client_cel"
                label="Celular"
                dense
              ></v-text-field>
            </v-col>

            <!--Documento-->
            <v-col cols="12" md="4">
              <v-text-field
                v-model="item.client_document"
                label="Documento"
                dense
              ></v-text-field>
            </v-col>

            <!--VIP-->
            <v-col cols="12" md="4" class="justify-center d-flex align-center">
              <span class="mr-3">VIP?</span>
              <v-btn-toggle v-model="item.vip">
                <v-btn disabled :value="true" height="40px"> sim </v-btn>
                <v-btn disabled :value="false" height="40px"> Não </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex">
        <v-spacer></v-spacer>
        <v-btn @click="cadastrarUsuario" class="xbColor">
          <v-icon>mdi-plus</v-icon>
          Cadastrar Usuário
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { postUsuarioSalesDuplicada } from "@/api/admin/usuarios.js";
import { inputRequired } from "@/plugins/utils.js";

export default {
  name: "DialogSalesDuplicadas",

  props: {
    dialogSalesDuplicadas: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
  },

  data() {
    return {
      loading: false,
      validForm: true,
      formRules: [inputRequired],
    };
  },

  methods: {
    cadastrarUsuario() {
      let usuario = {};

      usuario.nome = this.item.client_name;
      usuario.email = this.item.client_email;
      usuario.celular = this.item.client_cel;
      usuario.documento = this.item.client_document;
      usuario.vip = this.item.vip;
      usuario.sale_id = this.item.sale_id;

      postUsuarioSalesDuplicada(usuario).then((response) => {
        if (response.status === 201) {
          this.$toast.success("Criado com sucesso");
          this.loading = false;
          this.$emit("update:dialogSalesDuplicadas", false),
            this.$emit("fecth-duplicadas");
        }
        if (response.status === 200) {
          this.$toast.error(
            "Documento ou Email já existe favor alterar antes de salvar!"
          );
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style></style>
